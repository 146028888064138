import { memoize } from "@solvari/common-fe/helpers";

import wallet from "@/vue/apps/customer/store/modules/wallet";
import storeGenerator from "@/vue/apps/shared/store/index.js";
import dataLayer from "@/vue/apps/shared/store/modules/dataLayer/index.js";

import { alerts } from "../../shared/store/modules/alerts.js";
import filterSettings from "./modules/filter-settings/index.js";
import workplaceFilter from "./modules/workplace-filter/index.js";
import workplace from "./modules/workplace/index.js";

export const store = memoize(() =>
  storeGenerator({
    modules: {
      wallet,
      alerts,
      filterSettings,
      workplace,
      workplaceFilter,
      dataLayer,
    },
  }),
);
