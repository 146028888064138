import axios from "axios";

import { getEnv } from "@/vue/utils/solvariConfig.ts";

const http = new axios.create({
  baseURL: getEnv().network.pro.baseWithPrefix,
  headers: {
    Authorization: `Bearer ${getEnv().auth.jwtToken}`,
  },
});

export default {
  fetchCustomerWorkplaceData(localeCode) {
    return http.get(`customer/workplace/data/${localeCode}`);
  },

  fetchSavedFilter(localeCode) {
    return http.get(`workplace/get-saved-filters/${localeCode}`);
  },

  favoriteFilter(id) {
    return http.get(`workplace/set-favorite-filter/${id}`);
  },

  fetchFilter(id) {
    return http.get(`workplace/get-filter/${id}`);
  },

  fetchFilters(locale) {
    return http.get(`workplace/get-filters?locale=${locale}`);
  },

  fetchResults(params, signal) {
    return http.get("workplace/results", {
      params,
      signal,
    });
  },

  saveFilter(filter) {
    return http.post("workplace/save-filter", filter);
  },

  buyLead(leadId) {
    return http.get(`workplace/buy/${leadId}`);
  },

  fetchLead(leadId) {
    return http.get(`workplace/info/${leadId}`);
  },
};
