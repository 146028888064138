import Vue from "vue";
import VueI18n from "vue-i18n";
import Vuex from "vuex";

import type { VueIslandConfig } from "@/shared/vueIslands";

import { initVueIslands } from "@/shared/vueIslands";
import { leadDetails } from "@/vue/apps/customer/pages/leadDetails";
import i18n from "@/vue/apps/customer/plugins/i18n";
import { store } from "@/vue/apps/customer/store/store.js";
import { initAxiosInterceptors } from "@/vue/apps/shared/plugins/axios/index.js";
import SModal from "@/vue/apps/shared/plugins/sModal";

import "@solvari/common-fe/polyfills";
import "@/vue/apps/customer/utils/sentry";

import "@/platform/components/review-widget/reviewScore.css";
import "@/vue/apps/customer/styling/index.scss";

function main() {
  Vue.config.productionTip = false;

  Vue.use(SModal);
  Vue.use(VueI18n);
  Vue.use(Vuex);

  initAxiosInterceptors(store());

  void store().dispatch("auth/fetchUser");

  const pages: VueIslandConfig = {
    productsPage: () => import("@/vue/apps/customer/pages/Products.vue"),
    workplace: () => import("@/vue/apps/customer/pages/Workplace.vue"),
    workplaceLead: () => import("@/vue/apps/customer/pages/WorkplaceLead.vue"),
    workplaceFilter: () =>
      import("@/vue/apps/customer/pages/WorkplaceFilter.vue"),
    workplaceFiltersOverview: () =>
      import("@/vue/apps/customer/pages/WorkplaceFiltersOverview.vue"),
    creditsOverview: () =>
      import("@/vue/apps/customer/pages/CreditsOverview.vue"),
    crmApi: () => import("@/vue/apps/customer/pages/CrmApi.vue"),
  };

  const components: VueIslandConfig = {
    inbox: () => import("@/vue/components/inbox/InboxThread.vue"),
    leadSearch: () =>
      import("@/vue/apps/customer/components/lead-search/LeadSearch.vue"),
    walletBalance: () =>
      import("@/vue/apps/customer/components/wallet/WalletBalance.vue"),
    reviewInvite: () =>
      import("@/vue/apps/customer/components/review-invite/ReviewInvite.vue"),
    companyInfoCard: () =>
      import(
        "@/pro/components/dashboard/company-info-card/SCompanyInfoCard.vue"
      ),
    qualityScoreCard: () =>
      import(
        "@/pro/components/dashboard/quality-score-card/SQualityScoreCard.vue"
      ),
    createCampaignModal: () =>
      import("@/vue/apps/customer/components/modal/CreateCampaignModal.vue"),
    editProject: () => import("@/pro/pages/project/EditProject.vue"),
  };

  initVueIslands(
    { ...pages, ...components },
    { i18n: i18n(), store: store() as unknown },
  );

  leadDetails();
}

main();
