export default {
  getLoading: (state) => state.loading,
  getLocale: (state) => state.locale,
  getAvailableUsers: (state) => state.availableUsers,
  getRadius: (state) => state.filter.radius,
  getZipCode: (state) => state.filter.zipCode,
  getSlotsAvailable: (state) => state.filter.slotsAvailable,
  getContact: (state) => state.filter.contact,
  getOrderBy: (state) => state.filter.orderBy,
  getProducts: (state) => state.filter.products,
  getTextSearchTags: (state) => state.filter.textSearchTags,
  getNotificationFrequency: (state) => state.filter.notificationFrequency,
  getNotificationFrequencyDay: (state) => state.filter.notificationFrequencyDay,
  getNotificationFrequencyHour: (state) =>
    state.filter.notificationFrequencyHour,
  getNotificationFrequencyWeekend: (state) =>
    state.filter.notificationFrequencyWeekend,
  getUsers: (state) => state.filter.users,
  getWithDescription: (state) => state.filter.withDescription,
  getName: (state) => state.filter.name,
  getId: (state) => state.filter.id,
  getIsFavorite: (state) => state.filter.isFavorite,
};
