export default {
  push({ commit }, { event, label, value }) {
    // Create format for GTM
    const payload = {
      event,
      eventModel: {
        label,
        value,
      },
    };
    // Add locally
    commit("pushEvent", payload);
    // Push to dataLayer
    window.dataLayer.push(payload);
  },
};
