import axios from "axios";

import { getEnv } from "@/vue/utils/solvariConfig.ts";

const http = new axios.create({
  baseURL: `${getEnv().network.api.base}/system/v1/customer/products`,
  headers: {
    Authorization: `Bearer ${getEnv().auth.jwtToken}`,
  },
});

export default {
  /**
   * Fetches settings for the logged-in user
   */
  fetchSettings() {
    return http.get("filter-settings");
  },
  /**
   * Store settings for the logged-in user
   */
  storeSettings(settings) {
    return http.post("filter-settings", settings);
  },
  /**
   * Fetches all available brands funds for the logged-in user
   */
  fetchCategoriesForProduct(product_id) {
    return http.get(`${product_id}/brands`);
  },
};
